import React from 'react'
import PropTypes from 'prop-types'
import Seo from '~components/Seo'
import Section from '~components/Section'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Screen } from '../templates/Checklist'
import ChecklistSlide from '~components/ChecklistSlide'
import { mobile, tablet } from '~styles/global'

const Search = ({ data }) => {

	const [progress, setProgress] = useState(0)

	let slides = [
		{type: 'welcome', section: 'welcome', background: '#F3F1F0'},
		{type: 'intro', section: 'intro'},
		{type: 'index', section: 'intro'},
	]

	return (
		<>
			<Seo
				title="Checklist"
			/>
			<Screen slide={slides[progress]}>
			<StyledChecklistSlide 
					slide={slides[progress]} 
					next={() => setProgress(progress + 1)}
					key={progress}
					slides={slides}
					checklist={{
						slug: { current: 'intro' } 
					}}
				/>
			</Screen>
		</>
	)
}

const StyledChecklistSlide = styled(ChecklistSlide)`
	margin: var(--m) 0;
	${mobile}{
		margin: var(--s) 0;
	}
`

const Wrap = styled.div`
	background: #F3F1F0;
	padding: 40px;
	margin-right: 60px;
`

Search.propTypes = {
	data: PropTypes.object,
}


export default Search
